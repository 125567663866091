/* eslint-disable no-unused-vars */
import pack from '/package.json'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { DashboardAPI } from '../api'
import authService from '../utils/authservice'
import { HOC, Context, Header, Loading } from '../components'
import DashboardLocation from '../containers/dashboard/dashboard.location.container'

import '@progress/kendo-theme-default/dist/all.css'
import 'react-circular-progressbar/dist/styles.css'
import '../../node_modules/@syncfusion/ej2-base/styles/material.css'
import '../../node_modules/@syncfusion/ej2-lists/styles/material.css'
import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css'
import '../../node_modules/@syncfusion/ej2-popups/styles/material.css'
import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css'
import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css'
import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css'
import '../../node_modules/@syncfusion/ej2-react-grids/styles/material.css'
import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css'
import '../../node_modules/@syncfusion/ej2-react-grids/styles/material.css'
import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css'
import '../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css'

import '../assets/css/home.css'
import '../assets/css/kendo.css'
import '../assets/css/table.css'
import '../assets/css/globals.css'

const MyApp = ({ Component, pageProps }) => {
  console.log('version: ', pack['version']) //update version on package.json

  const router = useRouter()
  const path = router.asPath
  const param = router.query
  const isPrintOut = path.includes('printout')
  const isSingleSignOn = path.includes('single')
  const isInternal = path.includes('internal')
  const isExternal = path.includes('external')
  const isAuthentication = path.includes('authentication')
  const isLogout = path.includes('logout')
  // const isDashboard = path.includes('dashboard') || path === '/'
  const [loading, setLoading] = useState(false)
  const [expired, setExpired] = useState(false)

  useEffect(() => {
    authService.loadAuthModule()
  })

  // useEffect(() => {
  //   _checkExpired()
  // }, [Component])

  // const _checkExpired = async () => {
  //   try {
  //     const token = localStorage.getItem('token')
  //     if (token != '' && !isSingleSignOn && !isInternal && !isExternal && !isAuthentication && !isLogout && !isDashboard) {
  //       const response = await DashboardAPI.CheckExpired()
  //       setLoading(false)
  //       setExpired(response.toString().includes('401'))
  //     }
  //   } catch (error) {
  //     console.log('ERROR EXPIRED ', error)
  //   }
  // }

  const _loading = () => {
    return <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loading />
    </div>
  }

  const Content = () => {
    const { Location: LocationReducer } = useSelector((state) => state.usersProfileReducer)
    if (isSingleSignOn || isPrintOut || isInternal || isExternal || isAuthentication || isLogout) {
      return (
        <div>
          <Component {...pageProps} />
        </div>
      )
    } else {
      if(loading) {
        _loading()
      }

      if(expired && !loading) {
        return <Header expired/>
      }

      const { roleMapping } = useSelector((state) => state.usersLoginReducer)
      // REDIRECT TO LOGIN URL IF THERE IS NO ROLEMAP
      if (!roleMapping) {
        const filterLogout =
          '[{"field":"type","type":"text","value":"Lintas_URL"},{"field":"is_active","type":"bool","value":"true"}]'
        DashboardAPI.GetHeaderButtonUrls(0, 100, filterLogout)
          .then((res) => {
            const { data: logoutUrl } = res
            if (logoutUrl && logoutUrl[0] && logoutUrl[0].Value) {
              localStorage.clear()
              param.sr_no ? localStorage.setItem('url', window.location.href) : null
              window.location = logoutUrl[0].Value
            } else {
              router.push('/')
            }
          })
          .catch((err) => {
            console.log(err)
            router.push('/')
          })
        return (
          _loading()
        )
      }

      const defaultAllowedPaths = ['/', '/notification', '/service-request-detail']
      const allowedPaths = defaultAllowedPaths.concat(roleMapping.map((e) => e.path.toLowerCase()))
      const isAllowed = allowedPaths.indexOf(router.pathname.toLowerCase()) > -1
      if (!isAllowed) {
        // GLOBAL REDIRECTION FOR WHEN USER TRY TO ACCESS PATHS THAT NOT STATED IN THEIR ROLEMAPPING
        router.push('/')
        return (
          _loading()
        )
      } else {
        return LocationReducer ? (
          <Header>
            <Component {...pageProps} />
          </Header>
        ) : (
          <DashboardLocation />
        )
      }
    }
  }

  return (
    <Context>
      <HOC>
        <Content />
      </HOC>
    </Context>
  )
}

export default MyApp
