import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core'
import { SET_USER_PROFILE_LOCATION } from '../../redux/actions'

import { Images } from '../../assets'
import { Button } from '../../components'
import { Colors, FontSize } from '../../styles'

import { DropdownStyled } from './dashboard-dropdown.styled'
import { DivAbsoulte, DivDropdownIcon, IconLocation } from './dashboard.location.styled'

const useStyles = makeStyles(() => ({
  formControl: {
    flexGrow: 9,
  },
}))

const DashboardLocation = () => {
  const dispatch = useDispatch()
  const { UserLocations } = useSelector((state) => state.usersLoginReducer)

  const [Location, setLocation] = useState('Port Location')
  const width = '100vw'
  const height = 'calc(88.72vh)'
  const heightFull = 'calc(100vh)'
  const classes = useStyles()

  const clearSW = async () => {
    const serviceWorker = await navigator.serviceWorker.getRegistration()

    if (serviceWorker) {
      await serviceWorker.unregister()
    }
  }
  useEffect(() => {
    clearSW()
  }, [])

  const updateLocation = (value) => {
    setLocation(value)
  }
  const submitLocation = () => {
    const locationValue = UserLocations.find((e) => e.LocationDescription === Location)
    dispatch({
      type: SET_USER_PROFILE_LOCATION,
      payload: locationValue,
    })
  }

  return (
    <>
      <img
        style={{
          position: 'absolute',
          right: 0,
          zIndex: 0,
          width: '100%',
          height: heightFull,
        }}
        src={Images.BackgroundLocation}
      />
      <DivAbsoulte>
        <div
          style={{
            backgroundImage: Images.BackgroundLocation,
            width: width,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: height,
          }}
        >
          <DivDropdownIcon>
            <IconLocation>
              <img
                src={Images.LocationIcon}
                style={{
                  color: Colors.aliceBlue,
                  height: '80%',
                  width: '40%',
                }}
              />
            </IconLocation>
            <DropdownStyled>
              <FormControl className={classes.formControl}>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  style={{ height: '40px' }}
                  id='demo-simple-select'
                  value={Location}
                  onChange={(e) => {
                    updateLocation(e.target.value)
                  }}
                >
                  <MenuItem key={0} value='Port Location'>
                    <em>Port Location</em>
                  </MenuItem>

                  {UserLocations &&
                    UserLocations.map((e, i) => (
                      <MenuItem key={i} value={e.LocationDescription}>
                        {e.LocationDescription}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </DropdownStyled>
          </DivDropdownIcon>

          <Button
            type='submit'
            color='primary'
            id='mdl_dialog_submit_btn'
            style={{
              marginTop: 15,
              borderRadius: '20px',
              float: 'right',
              fontSize: FontSize.small,
            }}
            onClick={location ? submitLocation : ''}
          >
            OK
          </Button>
        </div>
      </DivAbsoulte>
    </>
  )
}
export default DashboardLocation
