import styled from 'styled-components'
import { Colors } from '../../styles'
// import ReactTooltip from 'react-tooltip'
// import { CircularProgressbar } from 'react-circular-progressbar'

// import { Colors, FontSize } from '../../styles'

// flex-grow: 8; margin-top: 8px;

export const DropdownStyled = styled.div`
  flex-grow: 9;
  display: flex;
  margin-left: 10px;
  & div.MuiFormControl-root {
    &
      div.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
      width: 100%;
      &.MuiInput-underline:before {
        display: none;
      }
      &.MuiInput-underline:after {
        display: none;
      }
    }
  }
`
export const DivDropdownLocation = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;

  flex-direction: row;
  border-radius: 10px;
  height: 3.07vh;
  width: 19.27vw;

  box-shadow: 0 3px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
`
export const IconLocation = styled.div`
  display: flex;
  align_items: center;
  justify-content: center;
  width: 20%;

  border-right-color: ${Colors.aliceBlue};
  border-right-style: solid;
`

export const DropdownStyledSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  & div.MuiFormControl-root {
    &
      div.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
      &.MuiInput-underline:before {
        display: none;
      }
      &.MuiInput-underline:after {
        display: none;
      }
    }
  }
`
