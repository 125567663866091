import styled from 'styled-components'
import { Images } from '../../assets'
import { Colors } from '../../styles'

export const IconLocation = styled.div`
  display: flex;
  alignitems: center;
  flex-grow: 1;
  justify-content: center;
  padding-top: 5px;
  height: 100%;
  border-right-color: ${Colors.aliceBlue};
  border-right-style: solid;
`

export const DivBackgroundLocation = styled.div`
  background-image: ${Images.BackgroundLocation};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const DivAbsoulte = styled.div`
  position: absolute;
`

export const DivDropdownIcon = styled.div`
  display: flex;
  background-color: ${Colors.white};
  justify-content: space-between;
  border-radius: 25px;
  width: 500px;
  height: 40px;
`
